
import { defineComponent, onMounted, ref, watch } from "vue";
import arraySort from "array-sort";
import "jquery/dist/jquery.min.js";
//Datatable Modules

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JQuery from "jquery";
import { Actions } from "@/store/enums/StoreEnums";

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "section_table",
  props: {
    orderedBy: {
      default: "desc",
      type: String,
    },
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    page: {
      type: Number,
      required: false,
    },
    tableData: { type: Array, required: true },
    loading: { type: Boolean, default: false },
  },
  emit: ["orders", "dataExist"],
  setup: function (props, { emit }) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    // eslint-disable-next-line vue/no-setup-props-destructure
    let order = props.orderedBy;
    let table;
    const currentSort = ref<string>("");
    let data = ref(props.tableData);
    const emptyTableText = "No data found";
    const tableRef = ref<HTMLElement | null>(null);

    const sort = (columnName, sortable) => {
      if (sortable === false) {
        return;
      }

      if (order === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
      currentSort.value = columnName + order;
      let data = {
        column: columnName,
        sorting: order,
      };
      emit("orders", data);
    };
    watch(
      () => props.tableData,
      async () => {
        // eslint-disable-next-line
        if (props.loading == false) {
          // eslint-disable-next-line
          const fn: JQuery & { dataTable?: any } = JQuery.fn;
          const $dt: JQuery & { dataTable?: any } = JQuery("#global-table");
          await emit("dataExist", true);
          if (!fn.dataTable.isDataTable("#global-table")) {
            await changeData();
            await newDataTable();
          } else {
            await changeData();
            await $dt.dataTable().fnDraw();
          }
        }
      }
    );

    async function changeDataTable() {
      let dataExist = true;
      if (data.value.length > 0) {
        // eslint-disable-next-line
        const $dt: JQuery & { dataTable?: any } = JQuery("#global-table");
        dataExist = false;
        await $dt.dataTable().fnDestroy();
        await changeData();
        await newDataTable();
        dataExist = true;
        emit("dataExist", dataExist);
      }
    }

    function newDataTable() {
      if (data.value.length > 0) {
        JQuery("#notFound").hide();
        // eslint-disable-next-line
        const $dt: JQuery & { dataTable?: any } = JQuery("#global-table");
        // eslint-disable-next-line
        table = $dt.dataTable({
          ordering: true,
          searching: false,
          paging: true,
          processing: false,
          info: true,
          lengthMenu: [
            [10, 25, 50, -1],
            [10, 25, 50, "All"],
          ],
          dom: "Blfrtip",
          buttons: [
            { extend: "copy", className: "btn btn-primary btn-sm" },
            { extend: "csv", className: "btn btn-primary btn-sm" },
            { extend: "excel", className: "btn btn-primary btn-sm" },
            { extend: "pdf", className: "btn btn-primary btn-sm" },
            { extend: "print", className: "btn btn-primary btn-sm" },
          ],
          // eslint-disable-next-line
          initComplete: function() {
            let $buttons = JQuery(".dt-buttons").hide();
            JQuery(".export").on("click", function (e) {
              e.preventDefault();
              $buttons.find(`.buttons-${this.id}`).click();
            });
            JQuery("#exportLink").on("change", function () {
              let btnClass = JQuery(this).find(":selected")[0].id
                ? ".buttons-" + JQuery(this).find(":selected")[0].id
                : null;
              if (btnClass) $buttons.find(btnClass).click();
            });
          },
        });
      } else {
        JQuery(".item-tr").remove();
        JQuery("#notFound").show();
      }
    }

    function changeData() {
      data.value = props.tableData;
      return data.value;
    }

    return {
      data,
      sort,
      emptyTableText,
      currentSort,
      tableRef,
    };
  },
});
